import axios from "axios";
import { roles } from "../config";
import { CurrentUserHasRole } from "./auth";
import { CurrentSiteIdGet } from "./userSettings";

export const userPreferencesGetList = async () => {
	const siteId = CurrentSiteIdGet();

	let dashboardForUserId = null;
	if (CurrentUserHasRole(roles.Distributor)) {
		dashboardForUserId = localStorage.getItem("viewDashboardAsUserId");
	}

	try {
		const result = await axios({
			method: "get",
			url: "v2/UserPreferences",
			params: {
				SiteId: siteId,
				dashboardForUserId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const userPreferenceSave = async (key, value) => {
	const siteId = CurrentSiteIdGet();

	let dashboardForUserId = null;
	if (key === "Dashboard" && CurrentUserHasRole(roles.Distributor)) {
		dashboardForUserId = localStorage.getItem("viewDashboardAsUserId");
	}

	try {
		await axios({
			method: "post",
			url: "v2/UserPreference",
			data: {
				SiteId: siteId,
				dashboardForUserId,
				UserPreference: {
					PreferenceKey: key,
					PreferenceValue: value,
				},
			},
		});
	} catch (err) {
		console.error(err);
	}
};
