import React, { useState, useEffect, useContext } from "react";
import local from "../../localization/strings";
import { toast } from "react-toastify";
import { Button, Row, Col, Alert } from "reactstrap";
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { CurrentUserHasRole } from "../../api/auth";
import { roles } from "../../config";
import { isFeatureEnabled, features } from "../../config";
import { userCreate, userUpdate, userGet, userDelete, userUpdateSite } from "../../api/users";
import { UserPreferencesContext } from "../../context/userPreferences";
import confirm from "reactstrap-confirm";
import { getDepartments } from "../../api/department";

const UserEdit = ({ userId, setUserId, setUserEdit }) => {
	const [data, setData] = useState(undefined);
	const { siteName } = useContext(UserPreferencesContext);
	const [error, setError] = useState(undefined);
	const [departments, setDepartments] = useState([]);

	const deleteUser = async () => {
		var result = await confirm({
			title: local.TF_Delete_User,
			message: local.TF_Delete_User_Are_You_Sure,
			confirmColor: "danger",
			confirmText: local.TF_Delete_User,
		});

		if (result) {
			await userDelete(userId);
			toast.success(local.TF_User_successfully_deleted);
			cancelEditUser();
		}
	};

	const cancelEditUser = () => {
		setData(undefined);
		setUserEdit(false);
		setUserId(undefined);
	};

	useEffect(() => {
		const LoadUser = async () => {
			var result = await userGet(userId);

			if (result && !result.departmentId) {
				result.departmentId = "";
			}

			setData(result);
		};

		const LoadDepartments = async () => {
			setDepartments(await getDepartments());
		};

		if (userId) {
			LoadUser();
		} else {
			setData({ sendInvite: true });
		}

		if (isFeatureEnabled(features.Departments)) {
			LoadDepartments();
		}
	}, [userId]);

	async function save(_e, values) {
		if (userId) {
			const resultUpdate = await userUpdate(values.email, values.displayName, values.role, siteName, values.newEmail);

			switch (resultUpdate) {
				case "LASTADMIN":
					setError(local.TS_LastAdmin);
					return;
				case "DuplicateUserName":
					setError(local.TS_EmailTaken);
					return;
				default:
					break;
			}

			if (values.departmentId !== data.departmentId) {
				await userUpdateSite(userId, null, values.departmentId);
			}

			setError(undefined);
			toast.success(local.TF_User_successfully_updated);
			cancelEditUser();
		} else {
			const resultCreate = await userCreate(values.email, values.displayName, values.role, siteName, values.departmentId, values.sendInvite);

			switch (resultCreate) {
				case "DUP":
					setError(local.TS_Duplicate_User);
					return;
				case "UPGRADE":
					setError(local.TS_Max_Lite_Users);
					return;
				case "DuplicateUserName":
					setError(local.TS_LastAdmin);
					return;
				case "Existing":
					setError(undefined);
					toast.success(local.TF_User_successfully_added_Existing);
					cancelEditUser();
					return;
				default:
					setError(undefined);
					if (values.sendInvite) {
						toast.success(local.TF_User_successfully_added);
					} else {
						toast.success(local.TF_User_successfully_added_noinvite);
					}
					cancelEditUser();
					return;
			}
		}
	}

	const departmentValid = (value, ctx, _input, cb) => {
		let valid = true;

		switch (ctx.role) {
			case "DepartmentAdmin":
				if (value === "") {
					valid = local.TS_Required;
				}
				break;
			case "SiteAdmin":
				if (value !== "") {
					valid = local.TS_Invalid;
				}
				break;
			default:
				break;
		}
		cb(valid);
	};

	return (
		<>
			<Row>
				<Col className="text-left">
					<h5 className="mb-2">{userId ? local.TF_Editing_User : local.TF_Adding_User}</h5>
				</Col>
			</Row>
			{data && (
				<AvForm model={data} onValidSubmit={async (e, values) => await save(e, values)}>
					<AvField name="displayName" label={local.TS_Name} validate={{ required: { value: true, errorMessage: local.TS_Required } }} />
					<Row>
						<Col md={6}>
							<AvField type="select" name="role" label={local.TF_User_Type} validate={{ required: { value: true, errorMessage: local.TS_Required } }}>
								<option />
								{(isFeatureEnabled(features.MultipleSiteAdmins) || data.role === "SiteAdmin") && <option value="SiteAdmin">{local.TS_Site_Administrator}</option>}
								{isFeatureEnabled(features.Departments) && <option value="DepartmentAdmin">{local.TS_Department_Administrator}</option>}
								<option value="SecondaryUser">{local.TS_Secondary_User}</option>
							</AvField>
						</Col>
						{isFeatureEnabled(features.Departments) && (
							<Col md={6}>
								<AvField type="select" name="departmentId" label={local.TS_Department} validate={{ async: departmentValid }}>
									{/* If departments not in use, or user is site admin: */}
									<option value="">{local.TS_None}</option>
									{departments.map((item) => (
										<option key={item.id} value={item.id}>
											{item.name}
										</option>
									))}
								</AvField>
							</Col>
						)}
					</Row>
					<AvField name="email" label={local.TS_EmailAddress} disabled={userId !== undefined} validate={{ email: true, required: { value: userId === undefined, errorMessage: local.TS_Required } }} />
					{!userId && (
						<AvRadioGroup name="sendInvite" label={local.TF_SendInvite}>
							<AvRadio label={local.TS_No} value={false} />
							<AvRadio label={local.TS_Yes} value={true} />
						</AvRadioGroup>
					)}
					{userId && <AvField name="newEmail" label={local.TS_NewEmailAddress} validate={{ email: true }} />}
					{error && <Alert color="danger">{error}</Alert>}
					<Row>
						<Col>
							<Button color="primary" className="mt-2 float-left">
								{userId ? local.TS_Save_Changes : local.TF_Create_User}
							</Button>
							<Button color="secondary" className="mt-2 ml-2 float-left" onClick={cancelEditUser}>
								{local.TS_Cancel}
							</Button>
						</Col>
						{CurrentUserHasRole(roles.SiteAdmin) && userId && (
							<Col>
								<Button color="danger" size="sm" className="mt-3 float-right" onClick={() => deleteUser()}>
									{local.TF_Delete_User}
								</Button>
							</Col>
						)}
					</Row>
				</AvForm>
			)}
		</>
	);
};

export default UserEdit;
