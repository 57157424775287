import React, { useState } from "react";
import AssignSitesList from "./AssignSitesList";
import DistributorList from "./DistributorList";
import DistributorEdit from "./DistributorEdit";

const DistributorTab = () => {
	const [assignSitesToDistributor, setAssignSitesToDistributor] = useState(undefined);
	const [edit, setEdit] = useState(false);
	const [editId, setEditId] = useState(undefined);
	const [key, setKey] = useState(1);

	const cancelEdit = () => {
		setEdit(false);
		setKey((x) => ++x);
		setEditId(undefined);
	};

	const editDistributor = (id) => {
		setEditId(id);
		setEdit(true);
	};

	const cancelAssignSitesHandle = () => {
		setKey((x) => ++x);
		setAssignSitesToDistributor(undefined);
	};

	const assignSitesHandle = (distributor) => {
		setAssignSitesToDistributor(distributor);
	};

	if (assignSitesToDistributor) {
		return <AssignSitesList cancelAssignSites={cancelAssignSitesHandle} distributor={assignSitesToDistributor} />;
	}

	if (edit) {
		return <DistributorEdit id={editId} cancelEdit={cancelEdit} />;
	}

	return <DistributorList key={key} assignSites={assignSitesHandle} edit={editDistributor} />;
};

export default DistributorTab;
