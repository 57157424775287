import MaterialTable from "material-table";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { CurrentUserHasRole } from "../../api/auth";
import { usersForSiteGetList } from "../../api/users";
import { CurrentSiteIdGet } from "../../api/userSettings";
import { roles } from "../../config";
import { UserPreferencesContext } from "../../context/userPreferences";
import { tableIcons } from "../../helpers/tableIcons";
import local from "../../localization/strings";

const ViewAsWidgetDropdown = () => {
	const [users, setUsers] = useState([]);
	const { setViewDashboardAs } = useContext(UserPreferencesContext);

	const loadUsers = useCallback(async () => {
		var result = await usersForSiteGetList(CurrentSiteIdGet());
		result = result.filter((x) => x.role !== roles.Eltek && x.role !== roles.Distributor);
		setUsers(result);
	}, []);

	useEffect(() => {
		if (CurrentUserHasRole(roles.Distributor)) {
			loadUsers();
		}
	}, [loadUsers]);

	const columns = [
		{ field: "displayName", defaultSort: "asc", cellStyle: { width: 500, maxWidth: 500 }, headerStyle: { width: 500, maxWidth: 500 } }, //
	];

	return (
		<UncontrolledDropdown direction="up" className="allow-overflow">
			<DropdownToggle caret transform="shrink-3" color="falcon-warning">
				<span className="d-none d-md-inline-block ml-2">{local.TF_View_As_User}</span>
			</DropdownToggle>
			<DropdownMenu className="menu-border-blue p-2">
				<MaterialTable
					onRowClick={(_, rowData) => {
						setViewDashboardAs(rowData);
					}}
					columns={columns}
					data={users}
					title=""
					icons={tableIcons}
					options={{ toolbar: false, search: false, header: false, paging: false, showEmptyDataSourceMessage: false }}
				/>
			</DropdownMenu>
		</UncontrolledDropdown>
	);
};

export default ViewAsWidgetDropdown;
